<template>
  <div>
    <b-button v-b-modal.create-company-location class="button button--outline">
      {{ $t("company_locations.create_new_company_location") }}
    </b-button>
    <b-modal id="create-company-location" size="lg" hide-footer>
      <template #modal-header="{ close }">
        <button class="button button--nostyle ml-auto" @click="close()">
          <BIconX />
        </button>
      </template>
      <b-form
        class="create-company-location flex-form"
        @submit="submitForm"
        novalidate
      >
        <h4>{{ $t("form.create_company_location.title") }}</h4>
        <b-form-group
          label-for="location_name"
          :label="$t('form.create_company_location.location_name')"
          :state="!isDirty ? null : Boolean(data.name.length)"
          :invalid-feedback="$t('form_error.required_field')"
        >
          <b-form-input
            id="location_name"
            v-model="data.name"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-for="location_address"
          :label="$t('form.create_company_location.location_address')"
          :state="!isDirty ? null : isValidAddress(data.address)"
          :invalid-feedback="$t('common.invalid_address')"
        >
          <!-- TODO: NEEEEEEEEDS WORK ON ISVALIDADDREESS (SEE: "mannerheimintie 3") -->
          <b-form-input
            list="location_address"
            v-model="data.address"
            type="text"
            debounce="400"
            required
          ></b-form-input>
        </b-form-group>
        <datalist id="location_address">
          <option
            v-for="address in autocompleteAddresses"
            :key="address.properties.id"
          >
            {{ address.properties.label }}
          </option>
        </datalist>
        <b-alert show variant="danger" v-if="errors && errors.length">
          <ul class="list--nostyle">
            <li v-for="error in errors" :key="error">
              {{ error }}
            </li>
          </ul>
        </b-alert>
        <button
          type="submit"
          class="button button--filled-blue"
          @submit="submitForm"
        >
          {{ $t("form.save_and_close") }}
        </button>
      </b-form>
    </b-modal>
  </div>
</template>
<script>
import { BIconX } from "bootstrap-vue";
import formValidation from "@/mixins/formValidation";
import { mapState } from "vuex";
import addressAutocompleteValidation from "@/mixins/addressAutocompleteValidation";

const initData = {
  address: "",
  name: "",
};
export default {
  name: "create-company-location",
  components: { BIconX },
  data() {
    return {
      data: { ...initData },
      errors: [],
      isDirty: false,
    };
  },
  mixins: [formValidation, addressAutocompleteValidation],
  computed: mapState(["selectedCompany"]),
  methods: {
    async submitForm(e) {
      e.preventDefault();
      this.errors = [];
      this.isDirty = true;

      if (this.validForm(this.data) && this.isValidAddress(this.data.address)) {
        try {
          const res = await this.$store.dispatch(
            "workplaces/createWorkplaceToSelectedCompany",
            this.data
          );
          if (res) {
            this.$bvToast.toast(this.$t("toast.created_successfully"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            this.data = { ...initData };
            return this.$bvModal.hide("create-company-location");
          }
        } catch (err) {
          console.log(err);
          this.$bvToast.toast(err.toString(), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
        } finally {
          this.isDirty = false;
        }
      }

      if (!this.validForm(this.data)) {
        this.errors.push(this.$t("common.fill_all_fields"));
      }
      if (!this.isValidAddress(this.data.address)) {
        this.errors.push(this.$t("common.invalid_address"));
      }
    },
  },
};
</script>
<style lang="scss">
.create-company-location {
  padding: 0 2rem 2rem;

  h4 {
    font-weight: normal;
    margin-bottom: 2rem;
  }

  label {
    font-weight: 500;
    margin-bottom: 0;
  }

  input {
    max-width: 540px;
  }

  input#company_id,
  input#contact_phone {
    max-width: 200px;
  }
}
</style>
