import hsl from "./axiosConfig";

export const getControlInformationByType = async (
  type,
  locale = "fi",
  organizationId
) => {
  try {
    const response = await hsl.get(
      `/control-information/by-type/${type}/${locale}/${organizationId}`
    );
    return response.data;
  } catch {
    return `Unable to get control information: ${type}`;
  }
};
