<template>
  <Container>
    <form
      @submit="submitForm"
      class="detailed-info flex-form"
      :validated="validated"
      novalidate
    >
      <h4>{{ $t("title.detailed_info") }}</h4>
      <b-form-group
        id="insdustry_group"
        label-for="industry"
        :label="$t('form.edit_details.company_industry')"
        :state="Boolean(data.industry)"
        invalid-feedback="Something wrong with selected industry"
      >
        <b-form-select
          id="industry"
          v-model="data.industry"
          :options="industriesDropdown"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group
        id="number_of_staff_group"
        label-for="number_of_staff"
        :label="$t('form.edit_details.number_of_staff')"
        :state="parseInt(data.numberOfEmployees) > 0"
        :invalid-feedback="`${$t('form_error.required_field')} / ${$t(
          'form_error.less_than',
          { val: '1' }
        )}`"
      >
        <b-form-select
          id="number_of_staff"
          v-model="data.numberOfEmployees"
          :options="numberOfEmployeesDropdown"
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group
        id="use_as_reference_group"
        label-for="use_as_reference"
        :label="$t('form.edit_details.use_as_reference')"
      >
        <b-form-select
          id="use_as_reference"
          v-model="data.referencingAllowed"
          :options="useAsReferenceOptions"
        ></b-form-select>
      </b-form-group>
      <b-alert show variant="danger" v-if="error">{{ error }}</b-alert>
      <button
        type="submit"
        class="button button--filled-blue"
        :disabled="isSubmitting"
      >
        <div v-if="isSubmitting">
          <b-spinner small class="mr-2"></b-spinner>
          <span>{{ $t("common.submitting") }}...</span>
        </div>
        <span v-else>
          {{ $t("common.save") }}
        </span>
      </button>
    </form>
  </Container>
</template>
<script>
import Container from "../Container.vue";
import formValidation from "@/mixins/formValidation";
import { updateOrganization } from "@/api/organizationController";
import { mapState } from "vuex";
import { getControlInformationByType } from "@/api/controlInformationController";

const initData = {
  industry: null,
  numberOfEmployees: null,
  referencingAllowed: false,
};

export default {
  name: "edit-company-details",
  components: { Container },
  data() {
    return {
      data: { ...initData },
      isSubmitting: false,
      error: "",
      industries: [],
      numberOfEmployees: [],
      useAsReferenceOptions: [
        { value: true, text: this.$t("common.yes") },
        { value: false, text: this.$t("common.no") },
      ],
    };
  },

  computed: {
    ...mapState({
      selectedCompany: (state) => state.companies.selectedCompany,
      selectedCompanyId: (state) => state.companies.selectedCompanyId,
      selectedLocale: (state) => state.selectedLocale,
    }),
    industriesDropdown() {
      return this.industries.map((industry) => ({
        value: industry.id,
        text: industry.text,
      }));
    },
    numberOfEmployeesDropdown() {
      return this.numberOfEmployees.map((item) => ({
        value: item.id,
        text: item.text,
      }));
    },
  },

  mounted() {
    this.setIndustries();
    this.setNumberOfEmployees();
    this.setData();
  },
  watch: {
    selectedCompany() {
      this.setData();
    },
  },
  mixins: [formValidation],
  methods: {
    // formatEmployees(val) {
    //   return val.replace(/[^0-9]/g, "");
    // },
    setIndustries() {
      return getControlInformationByType(
        "industry",
        this.selectedLocale,
        this.selectedCompanyId
      ).then((res) => (this.industries = res));
    },
    setNumberOfEmployees() {
      return getControlInformationByType(
        "number_of_employees",
        this.selectedLocale,
        this.selectedCompanyId
      ).then((res) => (this.numberOfEmployees = res));
    },
    setData() {
      this.data = {
        industry: this.selectedCompany.industry,
        numberOfEmployees: this.selectedCompany.numberOfEmployees,
        referencingAllowed: this.selectedCompany.referencingAllowed,
      };
    },
    submitForm(e) {
      e.preventDefault();

      this.isSubmitting = true;
      if (this.validForm(this.data)) {
        this.error = "";
        const merged = { ...this.selectedCompany, ...this.data };
        return updateOrganization(merged).then((res) => {
          if (res.status === 200) {
            this.$bvToast.toast(this.$t("toast.updated_successfully"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            this.$store.dispatch("companies/getCompanies");
            this.error = "";
            return (this.isSubmitting = false);
          }
          this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
          return (this.isSubmitting = false);
        });
      }
      return (this.isSubmitting = false);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/HSLColors.scss";

h4 {
  margin-bottom: 1em;
}

label {
  font-weight: 500;
  margin-bottom: 0;
}

input,
select {
  max-width: 540px;
  display: block;
}

input#company_id,
select#number_of_staff {
  max-width: 200px;
}

.hsl-blue {
  color: $color-hsl-sininen;
}
</style>
