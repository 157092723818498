<template>
  <Container>
    <b-form
      @submit="submitForm"
      class="basic-info flex-form"
      :validated="validated"
      novalidate
    >
      <h4>{{ $t("title.basic_info") }}</h4>
      <b-form-group
        id="company_name_group"
        label-for="company_name"
        :label="$t('form.create_company.company_name')"
        :state="data.name.length > 0"
        :invalid-feedback="$t('common.fill_all_fields')"
      >
        <b-form-input
          id="company_name"
          v-model="data.name"
          type="text"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="company_id_group"
        label-for="company-id"
        :state="validCompanyId(data.ySign)"
        :label="$t('form.create_company.company_id')"
        :invalid-feedback="$t('common.invalid_company_id')"
      >
        <b-form-input
          id="company_id"
          v-model="data.ySign"
          type="text"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="company_hq_address_group"
        label-for="company_hq_address"
        :state="isValidAddress(data.address)"
        :label="$t('form.create_company.company_hq_address')"
        :invalid-feedback="$t('common.invalid_address')"
      >
        <b-form-input
          list="company_hq_address"
          v-model="data.address"
          type="text"
          debounce="400"
          required
        ></b-form-input>
      </b-form-group>
      <datalist id="company_hq_address">
        <option
          v-for="address in autocompleteAddresses"
          :key="address.properties.id"
        >
          {{ address.properties.label }}
        </option>
      </datalist>
      <b-alert show variant="danger" v-if="errors && errors.length">
        <ul class="list--nostyle">
          <li v-for="error in errors" :key="error">
            {{ error }}
          </li>
        </ul>
      </b-alert>
      <button
        type="submit"
        class="button button--filled-blue"
        :disabled="isSubmitting"
      >
        <div v-if="isSubmitting">
          <b-spinner small class="mr-2"></b-spinner>
          <span>{{ $t("common.submitting") }}...</span>
        </div>
        <span v-else>
          {{ $t("common.save") }}
        </span>
      </button>
    </b-form>
  </Container>
</template>
<script>
import Container from "../Container.vue";
import formValidation from "@/mixins/formValidation";
import addressAutocompleteValidation from "@/mixins/addressAutocompleteValidation";
import { mapState } from "vuex";
import { updateOrganization } from "@/api/organizationController";

const initData = {
  name: "",
  ySign: "",
  address: "",
};

export default {
  name: "edit-company-basic",
  components: { Container },
  data() {
    return {
      data: { ...initData },
      errors: [],
      isSubmitting: false,
    };
  },

  computed: mapState({
    selectedCompany: (state) => state.companies.selectedCompany,
  }),

  watch: {
    selectedCompany() {
      this.setData();
    },
  },
  mounted() {
    this.setData();
  },
  mixins: [formValidation, addressAutocompleteValidation],

  methods: {
    setData() {
      this.data = {
        name: this.selectedCompany.name,
        ySign: this.selectedCompany.ySign,
        address: this.selectedCompany.address,
      };
    },
    submitForm(e) {
      e.preventDefault();
      this.isSubmitting = true;
      this.errors = [];

      if (
        this.validForm(this.data) &&
        this.validCompanyId(this.data.ySign) &&
        this.isValidAddress(this.data.address)
      ) {
        const merged = { ...this.selectedCompany, ...this.data };
        return updateOrganization(merged).then((res) => {
          if (res.status === 200) {
            this.$bvToast.toast(this.$t("toast.updated_successfully"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            this.$store.dispatch("companies/getCompanies");

            this.errors = [];
            return (this.isSubmitting = false);
          }
          this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
          return (this.isSubmitting = false);
        });
      }
      this.isSubmitting = false;

      if (!this.validForm(this.data)) {
        this.errors.push(this.$t("common.fill_all_fields"));
      }
      if (!this.validCompanyId(this.data.ySign)) {
        this.errors.push(this.$t("common.invalid_company_id"));
      }
      if (!this.isValidAddress(this.data.address)) {
        this.errors.push(this.$t("common.invalid_address"));
      }
    },
    validCompanyId(id) {
      const re = /^[0-9]{7}-[0-9]{1}$/;
      return re.test(String(id));
    },
  },
};
</script>
<style lang="scss" scoped>
h4 {
  margin-bottom: 1em;
}

label {
  font-weight: 500;
  margin-bottom: 0;
}

input {
  max-width: 540px;
  display: block;
}

input#company_id {
  max-width: 200px;
}
</style>
