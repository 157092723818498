<template>
  <div>
    <Header :heading="this.$t('title.company_info')" />
    <SelectCompany />
    <template v-if="selectedCompanyId">
      <EditCompanyBasic />
      <div class="container">
        <hr />
      </div>
      <EditCompanyDetails />
      <div class="container">
        <hr />
      </div>
      <CompanyLocations />
      <div class="container">
        <hr />
      </div>
      <AdminUsers />
    </template>
  </div>
</template>
<script>
import Header from "@/components/Header.vue";
import SelectCompany from "../components/forms/SelectCompany.vue";
import EditCompanyBasic from "../components/forms/EditCompanyBasic.vue";
import EditCompanyDetails from "../components/forms/EditCompanyDetails.vue";
import CompanyLocations from "../components/CompanyLocations.vue";
import AdminUsers from "../components/AdminUsers.vue";
import { mapState } from "vuex";

export default {
  name: "company-info",
  metaInfo() {
    return {
      title: this.$t("navigation.company_info"),
    };
  },
  components: {
    Header,
    SelectCompany,
    EditCompanyBasic,
    EditCompanyDetails,
    CompanyLocations,
    AdminUsers,
  },
  data() {
    return {
      userRole: {},
    };
  },
  computed: mapState({
    selectedCompanyId: (state) => state.companies?.selectedCompany?.id,
  }),
};
</script>
<style lang="scss">
.form-container {
  border-radius: 5px;
  background-color: #fff;
  padding: 2rem;
}
</style>
