<template>
  <Container>
    <div class="heading">
      <div>
        <h4>{{ $t("admin_users.title") }}</h4>
        <p>{{ $t("admin_users.description") }}</p>
      </div>
      <CreateAdminUser />
    </div>
    <Loading v-if="isLoading" />
    <ul class="list--nostyle users-table" v-if="users && users.length > 0">
      <li class="table-header">
        <span>{{ $t("admin_users.table.select") }}</span>
        <span>{{ $t("admin_users.table.first_name") }}</span>
        <span>{{ $t("admin_users.table.last_name") }}</span>
        <span>{{ $t("admin_users.table.email") }}</span>
        <span>{{ $t("admin_users.table.created_at") }}</span>
        <span>{{ $t("admin_users.table.status") }}</span>
      </li>
      <li v-for="user in users" :key="user.hslId || user.invitationHash">
        <b-form-checkbox
          :id="`user-${user.hslId || user.invitationHash}`"
          v-model="selectedUsers"
          :name="`user-${user.hslId || user.invitationHash}`"
          :value="user.hslId || user.invitationHash"
        />
        <span>{{ user.firstName }}</span>
        <span>{{ user.lastName }}</span>
        <span>{{ user.email }}</span>
        <span>{{ formatDate(user.added) }}</span>
        <span>{{
          $t(`admin_users.status.${user.inUse ? "ACTIVE" : "INVITE_SENT"}`)
        }}</span>
      </li>
    </ul>
    <div v-if="users && users.length > 0">
      <button class="button button--outline mr-2" @click="deleteUsers">
        {{ $t("admin_users.delete") }}
      </button>
      <button class="button button--link" @click="clearSelection">
        {{ $t("admin_users.clear_selection") }}
      </button>
    </div>
  </Container>
</template>
<script>
import Container from "./Container.vue";
import CreateAdminUser from "./forms/CreateAdminUser.vue";
import { mapState } from "vuex";
import { format, parseISO } from "date-fns";
import Loading from "@/components/Loading.vue";
import { removeInvitations } from "@/api/userController";

export default {
  components: { Container, CreateAdminUser, Loading },
  computed: mapState({
    users: (state) => state.users.users,
    isLoading: (state) => state.users.isLoading,
    selectedCompanyId: (state) => state.companies.selectedCompanyId,
    selectedLocale: (state) => state.selectedLocale,
  }),
  data() {
    return {
      selectedUsers: [],
    };
  },
  methods: {
    formatDate(date) {
      return format(parseISO(date), "dd.MM.yyyy");
    },
    deleteUsers() {
      const response = removeInvitations(
        this.selectedCompanyId,
        this.selectedLocale,
        this.selectedUsers
      );
      response.then((res) => {
        if (res.status === 200) {
          this.$bvToast.toast(
            this.$t("toast.invitations_removed_succesfully"),
            {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            }
          );
          this.$store.dispatch(
            "users/getUsersByCompany",
            this.selectedCompanyId
          );
          return res;
        }
        this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
          toaster: "b-toaster-bottom-right",
          variant: "danger",
          noCloseButton: true,
        });
      });
    },
    clearSelection() {
      this.selectedUsers = [];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/style.scss";
.heading {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 1rem;

  & > *:first-child {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    & > *:first-child {
      width: 60%;
    }
  }
}

.users-table {
  overflow-x: auto;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 1rem;

  .table-header {
    display: none;
    @include media-breakpoint-up(md) {
      display: grid;
      font-weight: 500;
      padding: 1em 1em;
    }
  }

  li {
    display: grid;
    grid-template-columns: 50px 3fr 1fr;
    grid-template-rows: repeat(3, 1fr);
    min-width: fit-content;

    // MOBILE STYLES
    & > *:first-child {
      grid-column: 1;
      grid-row: 1 / -1;
    }

    & > *:nth-child(6) {
      grid-column: 3;
      grid-row: 1;
      text-align: right;
    }
    & > *:nth-child(5) {
      grid-column: 3;
      grid-row: 3;
      text-align: right;
      font-size: 0.85em;
    }
    & > *:nth-child(4) {
      font-size: 0.85em;
      grid-column: 2;
    }

    @include media-breakpoint-up(md) {
      grid-template-columns: 70px 100px 100px 250px 100px 1fr;
      grid-template-rows: 1fr;
      gap: 0 0.85em;

      & > * {
        grid-column: auto !important;
        grid-row: auto !important;
      }
      & > *:nth-child(4) {
        font-size: inherit;
      }
      & > *:nth-child(5) {
        font-size: inherit;
        text-align: inherit;
      }
    }

    padding: 1.5em 1em;
    align-items: center;
    justify-content: stretch;

    &:not(:last-child) {
      border-bottom: 1px solid #ddd;
    }
  }
}
</style>
