<template>
  <div>
    <b-button v-b-modal.create-admin-user class="button button--outline">
      {{ $t("admin_users.add_new") }}
    </b-button>
    <b-modal id="create-admin-user" size="lg" hide-footer>
      <template #modal-header="{ close }">
        <button class="button button--nostyle ml-auto" @click="close()">
          <BIconX />
        </button>
      </template>
      <form class="create-admin-user" @submit="submitForm">
        <h4>{{ $t("admin_users.add_new") }}</h4>
        <p>
          {{ $t("form.admin_users.body") }}
        </p>
        <p>
          {{ $t("form.admin_users.help_text") }}
        </p>
        <b-form-textarea
          id="emails"
          v-model="rawEmails"
          rows="6"
          required
        ></b-form-textarea>
        <div
          class="accepted-emails"
          v-if="parsedEmails && parsedEmails.length > 0"
        >
          <h5>
            {{
              parsedEmails.length === 1
                ? $t("form.admin_users.accepted_emails_singular")
                : $t("form.admin_users.accepted_emails_plural")
            }}
          </h5>
          <ul class="list--nostyle">
            <li v-for="email in parsedEmails" :key="email">{{ email }}</li>
          </ul>
        </div>
        <button
          type="submit"
          class="button button--filled-blue mb-4"
          @submit="submitForm"
          :disabled="!parsedEmails.length > 0 || isSubmitting"
        >
          <div v-if="isSubmitting">
            <b-spinner small class="mr-2"></b-spinner>
            <span>{{ $t("common.submitting") }}...</span>
          </div>
          <span v-else>
            {{ $t("form.send_invites") }}
          </span>
        </button>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { BIconX } from "bootstrap-vue";

export default {
  name: "create-admin-user",
  components: { BIconX },
  data() {
    return {
      rawEmails: "",
      isSubmitting: false,
    };
  },
  methods: {
    submitForm(e) {
      e.preventDefault();
      this.isSubmitting = true;
      this.$store
        .dispatch("users/sendInviteEmails", this.parsedEmails)
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$bvToast.toast(this.$t("toast.invites_sent"), {
              toaster: "b-toaster-bottom-right",
              variant: "success",
              noCloseButton: true,
            });
            this.isSubmitting = false;
            this.$bvModal.hide("create-admin-user");
            return;
          }
          this.$bvToast.toast(this.$t("toast.something_went_wrong"), {
            toaster: "b-toaster-bottom-right",
            variant: "danger",
            noCloseButton: true,
          });
          this.isSubmitting = false;
        });
    },
  },
  computed: {
    parsedEmails: function () {
      function validateEmail(email) {
        const re =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // Another one
        // /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>().,;\s@"]+\.{0,1})+[^<>().,;:\s@"]{2,})$/;
        return re.test(String(email).toLowerCase());
      }

      // Remove duplicates & filter out possible false values (eg. "", null etc.)
      const emailArr = [...new Set(this.rawEmails.split(/[,;\s]/))].filter(
        Boolean
      );

      // Validate emails with regexp function
      const parsedEmails = emailArr.filter((email) => validateEmail(email));
      return parsedEmails;
    },
  },
};
</script>
<style lang="scss">
h4 {
  margin-bottom: 1em;
}
textarea#emails {
  min-height: 200px;
  margin-bottom: 2rem;
}

.accepted-emails {
  margin-bottom: 1.5rem;
}
</style>
