<template>
  <Container>
    <h4>{{ $t("company_locations.title") }}</h4>
    <ul class="list--nostyle company-locations-list">
      <Loading v-if="isLoading" />
      <li v-for="item in workplaces" :key="item.id">
        <span class="title">{{ item.name }}</span
        ><span class="address">{{ item.address }}</span>
        <ConfirmationModal
          buttonClasses="button button--link"
          openModalText="Delete"
          :id="item.id"
          :onAccept="() => deleteItem(item.id)"
          :onAcceptButtonText="$t('common.delete')"
          class="delete"
        >
          <p class="text-center">
            Are you sure you want to delete<br />
            <strong>"{{ item.name }}"</strong>?
          </p>
        </ConfirmationModal>
      </li>
    </ul>
    <CreateCompanyLocation />
  </Container>
</template>
<script>
import Container from "./Container.vue";
import CreateCompanyLocation from "./forms/CreateCompanyLocation.vue";
import ConfirmationModal from "./forms/modals/ConfirmationModal.vue";
import Loading from "@/components/Loading.vue";
import { mapState } from "vuex";
export default {
  components: { Container, CreateCompanyLocation, ConfirmationModal, Loading },
  name: "company-locations",
  computed: mapState({
    workplaces: (state) => state.workplaces.workplaces,
    isLoading: (state) => state.workplaces.isLoading,
  }),
  methods: {
    deleteItem(id) {
      console.log("Item deleted", id);
    },
  },
};
</script>
<style lang="scss" scoped>
h4 {
  margin-bottom: 1em;
}

.company-locations-list {
  margin-bottom: 2rem;
  li {
    display: flex;
    padding: 1em 0.5em;
    border-bottom: 1px solid #ddd;

    &:first-child {
      border-top: 1px solid #ddd;
    }

    .title {
      font-weight: 500;
      width: 40%;
    }
    .address {
      width: 40%;
    }

    .delete {
      flex: 1;
      text-align: right;
      align-self: center;
    }
  }
}
</style>
